<template>
  <v-container>
    <BaseTableList title="Listagem de contratos para entrega de documentos">
      <template v-slot:table>
        <v-data-table
          class="b_datatable-cards"
          disable-sort
          hide-default-footer
          single-expand
          show-expand
          item-key="contractCode"
          :headers="headers"
          :items="items"
        >
          <template v-slot:[`item.situation`]="{ item }">
            <v-autocomplete
              class="b-field-select-grid"
              dense
              hide-details
              hide-selected
              single-line
              solo
              flat
              :readonly="!cannotEdit"
              :items="situation"
              @change="putContractSituation(item)"
              v-model="item.situation"
            />
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-container fluid>
                <v-row dense v-if="cannotEdit">
                  <v-col cols="12" xl="12" lg="12" md="12" sm="12">
                    <BaseButtonUpload
                      x-small
                      outlined
                      height="35"
                      color="primary"
                      title="Enviar outros arquivos"
                      tooltip="Clique para subir outros tipos de arquivos"
                      :item="item"
                      @uploadFile="uploadFile($event)"
                    />
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col
                    cols="12"
                    xl="3"
                    lg="3"
                    md="3"
                    sm="4"
                    v-for="(file, index) in item.files"
                    :key="index"
                  >
                    <CardDownloadUpload
                      :document="file"
                      :item="item"
                      :cannotEdit="cannotEdit"
                      @uploadDocument="uploadDocument($event)"
                      @downloadDocument="downloadDocument($event)"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </td>
          </template>
        </v-data-table>
      </template>
    </BaseTableList>

    <v-row v-if="cannotEdit">
      <v-col cols="12" xl="12" lg="12" md="12" sm="12">
        <PageReview :implementationId="implementationId" :step="2" />
      </v-col>
    </v-row>

    <v-divider class="my-5" />

    <v-row>
      <v-col class="text-right">
        <BaseButton @click="back" outlined color="primary" title="Voltar" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { downloadFile } from '@/helpers/download';
import { showMessage } from '@/helpers/messages';
import { ContractService } from '@/services/api/contracts';
import { ImplementationService } from '@/services/api/implementation';

export default {
  components: {
    PageReview: () => import('@/components/pages/implementation/page-review'),
    CardDownloadUpload: () => import('@/components/cards/card-download-upload')
  },

  data: () => ({
    items: [],
    situation: ['Enviado a operadora', 'Aprovado', 'Revisar', 'Em análise'],
    headers: [
      { text: '', value: 'data-table-expand' },
      { text: 'Código do contrato', value: 'contractCode' },
      { text: 'Operadora/Seguradora', value: 'insuranceCompanyName' },
      { text: 'Tipo do produto', value: 'productType' },
      { text: 'Status', value: 'situation' }
    ]
  }),

  props: {
    implementationId: {
      type: String,
      require: true
    }
  },

  created() {
    this.search();
  },

  computed: {
    cannotEdit() {
      const { situation } = this.$route.params;
      return situation === 'Concluído' ? false : true;
    }
  },

  methods: {
    async search() {
      try {
        const implementationService = new ImplementationService();
        const { status, data } =
          await implementationService.getImplementationContracts(
            this.implementationId
          );

        if (status === 200) {
          this.items = data;
        } else {
          this.items = [];
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async putContractSituation(item) {
      try {
        const implementationService = new ImplementationService();
        const { status } = await implementationService.putContractSituation({
          implementationId: this.implementationId,
          contractId: item.contractId,
          situation: item.situation
        });

        if (status === 204) {
          showMessage('success', 'Operação realizada com sucesso');
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async downloadDocument(item) {
      try {
        const contractService = new ContractService();
        const { status, data } = await contractService.downloadDocument({
          id: item.documentId
        });

        if (status === 200) {
          const { fileContent, fileName } = data;
          downloadFile(fileContent, fileName);
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async uploadFile(item) {
      try {
        const implementationService = new ImplementationService();
        const { status } =
          await implementationService.uploadImplementationContracts({
            implementationId: this.implementationId,
            documentId: item.documentId,
            contractId: item.contractId,
            file: item.file
          });

        if (status === 204) {
          showMessage('success', 'Operação realizada com sucesso');
          this.search();
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    back() {
      this.$emit('back');
    }
  }
};
</script>
